import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntl } from 'gatsby-plugin-intl';
import styles from './order-placed-banner.module.scss';

const OrderPlacedBanner = ({ userName, orderResult, orderMessage }) => {
  const intl = useIntl();

  return (
    <div className={styles.banner}>
      <div className={styles.avatarWrapper}>
        <span className={styles.emoji}>🎉</span>
      </div>
      <div className={styles.textWrapper}>
        {orderResult === 'processed' ? (
          <>
            <p className={styles.text}>
              Congratulations, <span>{userName}</span>!
            </p>
            <p className={clsx(styles.subtext, styles[orderResult.toLocaleLowerCase()])}>
              You&apos;re officially approved as a <strong>Publisher!</strong>
            </p>
          </>
        ) : (
          <>
            <p className={styles.text}>
              {`${intl.formatMessage({ id: 'hello' })}, `}
              <span>{userName}</span>!
            </p>
            <p className={clsx(styles.subtext, styles[orderResult.toLocaleLowerCase()])}>
              Your order has been {orderResult}!
            </p>
          </>
        )}
        {orderMessage && <p className={clsx(styles.text, styles.result)}>{orderMessage}</p>}
      </div>
    </div>
  );
};

OrderPlacedBanner.propTypes = {
  userName: PropTypes.string.isRequired,
  orderResult: PropTypes.string.isRequired,
  orderMessage: PropTypes.string.isRequired,
};

export default OrderPlacedBanner;

import React from 'react';

export const Check = () => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.411 0.763117C11.7678 1.04048 11.8321 1.55451 11.5548 1.91124L5.19344 10.093C5.05177 10.2752 4.83974 10.3892 4.60959 10.4067C4.37946 10.4242 4.15262 10.3437 3.98502 10.185L0.528145 6.91228C0.200004 6.60162 0.185834 6.08377 0.496494 5.75563C0.807154 5.42749 1.325 5.41332 1.65314 5.72398L4.45514 8.3767L10.2629 0.90684C10.5402 0.550106 11.0543 0.485759 11.411 0.763117Z"
        fill="#00C2AE"
      />
    </svg>
  );
};

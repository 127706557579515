import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Button from '../components/button';
import OrderPlacedBanner from '../components/order-placed-banner';
import OrderSummary from '../components/order-summary';
import { externalLinks } from '../helpers/navigation';
import { getSearchParam } from '../helpers/url';
import { selectElectronicSignments, selectEntityUserDetails } from '../store/selectors/entities';
import styles from '../styles/pages/checkout-success.module.scss';
import { getInstanceName, SIGNING_AUDIT_TRAIL_TYPE } from '../helpers/utils';
import { setLoader, toggleLiveChat } from '../store/actions';
import { selectShowVatAmount } from '../store/selectors';
import getOrderById from '../services/api/actions/getOrderById';
import Loader from '../components/loader';
import getSignedAgreement from '../services/api/actions/getSignedAgreement';
import { Check } from '../icons/svg/check';
import { RefreshIcon } from '../icons/svg/refresh';
import { Client } from '../../config/prismic';

const ORDER_STATUSES = {
  PENDING: 'Pending',
  PAID: 'Paid',
  ON_HOLD: 'On hold',
  PROCESSING: 'Processing',
  CANCELED: 'Canceled',
  REFUNDED: 'Refunded',
  BLOCKCHAIN_PENDING: 'Blockchain Pending',
  WITH_ERROR: 'Error',
  EXPIRED: 'Expired',
};

const query = graphql`
  query {
    bgLeft: file(relativePath: { eq: "confeti-left.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    bgRight: file(relativePath: { eq: "confeti-right.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 507) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

let counter = 0;

const CheckoutSuccess = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const userDetails = useSelector(selectEntityUserDetails);
  const showVat = useSelector(selectShowVatAmount);
  const electronicSignments = useSelector(selectElectronicSignments);

  const [auditTrialId, setAuditTrialId] = useState('');

  const [orderStatus, setOrderStatus] = useState(null);
  const [orderMessage, setOrderMessage] = useState(null);

  const isClient = typeof window === 'object';
  const publisherDashboardMessage = 'Unlock your Publisher Dashboard';
  const data = useStaticQuery(query);

  const orderInfo = isClient ? JSON.parse(localStorage.getItem('boughtProducts')) || {} : {};

  const userName = userDetails && userDetails.firstName;

  const siteName = getInstanceName();
  const title = intl.formatMessage({ id: 'pageTitle.checkoutSuccess' });
  const downloadAgreementTxt = intl.formatMessage({
    id: 'order-summary.download_agreement_details',
  });

  const orderResult = isClient && getSearchParam('complete', window.location.href);

  const getOrderMessage = () => {
    if (isClient && orderResult) {
      if (orderResult.toLowerCase() === 'unknown' || orderResult.includes('gateway')) {
        return 'processing';
      }
      if (orderResult === 'approved' || orderResult === 'completed') {
        return 'processed';
      }
      return orderResult;
    }
    return 'processing';
  };

  const successStatuses = ['approved', 'completed', 'Paid'];

  const orderResultMessage = orderStatus || getOrderMessage();

  const isOrderPlaced = successStatuses.includes(orderResult);

  useEffect(() => {
    if (!isOrderPlaced) {
      dispatch(toggleLiveChat(true));
    }
  });

  const redirectToBeBackOffice = () => {
    if (isClient) {
      localStorage.removeItem('audit-trial-id');
    }
    window.location.href = 'https://www.bebackoffice.com/refreshidentity';
  };

  const redirectToAlternativePm = (e) => {
    e.preventDefault();
    window.location.href = externalLinks.alternativePaymentMethod;
  };

  //! 1 START RETRY LOGIC
  useEffect(() => {
    if (!successStatuses.includes(orderResult)) {
      // eslint-disable-next-line no-use-before-define
      checkAndRequest();
    }
    dispatch(setLoader(false));
  }, [orderResult]);

  //! 2 DO RETRY 5 TIMES
  const checkAndRequest = () => {
    if (counter > 5) {
      return setOrderStatus('declined');
    }
    // eslint-disable-next-line no-use-before-define
    return setTimeout(retryOrderInfo, 3000);
  };

  //! 3 RETRY REQUEST AND RESPONSE CHECK
  const retryOrderInfo = async () => {
    const id = orderInfo && orderInfo.order ? `/${orderInfo.order}` : '';

    if (!id) {
      return setOrderStatus('declined');
    }

    const { payload } = dispatch(getOrderById.withQuery(id).action());

    if (payload) {
      const success = payload.data.status === 'Paid';

      if (success) {
        return setOrderStatus('success');
      }

      const errorStatus = [ORDER_STATUSES.CANCELED, ORDER_STATUSES.WITH_ERROR].includes(
        payload.data.status
      );

      if (errorStatus) {
        setOrderMessage(payload.data.message);
        setOrderStatus('failed');
      }
    } else {
      // eslint-disable-next-line no-plusplus
      counter++;
      checkAndRequest();
    }

    return null;
  };

  const downloadAgreementFiles = async (e) => {
    e.preventDefault();
    if (!isClient) {
      return;
    }
    dispatch(setLoader(true));
    const res = await dispatch(getSignedAgreement.withQuery(`${auditTrialId}`).action());
    dispatch(setLoader(false));
    if (res.payload.data?.data?.url) {
      if (isClient) {
        const url = await fetch(res.payload.data?.data?.url)
          .then((r) => r.blob())
          .then((blob) => window.URL.createObjectURL(blob));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'ibi_agreement_and_related_policies.pdf');
        document.body.appendChild(link);
        link.click();
        link.remove();
      }
    }
  };

  useEffect(() => {
    if (isClient && SIGNING_AUDIT_TRAIL_TYPE.SIGNING_COMPLETED) {
      const id = localStorage.getItem('audit-trial-id');
      if (id) {
        setAuditTrialId(id);
      }
    }
  }, []);

  const renderOrderInfo = () => {
    if (!orderStatus && !successStatuses.includes(orderResult)) {
      return <Loader isLoading isSmall={false} />;
    }

    if (orderStatus === 'declined') {
      return (
        <div className={styles.info}>
          <h4>Sorry we are still waiting for your payment</h4>
          <span>
            Your order has <strong>NOT</strong> been completed yet, we are still waiting to receive
            the funds. If your subscriptions does <strong>NOT</strong> get activated within the next
            5 minutes, please retry the payment again. In the meantime you can check your{' '}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="" onClick={redirectToBeBackOffice}>
              ORDER STATUS HERE!
            </a>
            <div className={styles.note}>
              <strong>NOTE:</strong>
              Bitcoin payment confirmation may take up to 1 hour.
            </div>
          </span>
        </div>
      );
    }

    return (
      <OrderSummary
        isOrderPlaced={isOrderPlaced}
        showVat={showVat}
        electronicSignments={electronicSignments}
      />
    );
  };

  return (
    <>
      <SEO title={`${title} - ${siteName}`} />
      <Layout fluid pageWrapperClassName="page_wrapper">
        <div className="container">
          <div className={styles.contentWrapper}>
            <div className={styles.banner}>
              <OrderPlacedBanner
                userName={userName}
                orderResult={orderResultMessage}
                orderMessage={orderMessage}
              />
            </div>

            <div>
              <div className={styles.summary}>{renderOrderInfo()}</div>
              {auditTrialId && (
                <div>
                  <div className={styles.downloadWrapper}>
                    <span>
                      <i className="icon-download" />
                      <a href="/" onClick={(e) => downloadAgreementFiles(e)}>
                        {downloadAgreementTxt}
                      </a>
                    </span>
                  </div>
                  <div className={styles.lastContentWrapper}>
                    <div className={styles.lastContent}>
                      <Check />
                      <p>Your agreement is signed</p>
                    </div>
                    <div className={styles.lastContent}>
                      <Check />
                      <p>Your account is approved</p>
                    </div>
                    <div className={styles.lastContent}>
                      <RefreshIcon />
                      <a
                        href="https://www.bebackoffice.com/refreshidentity"
                        className={styles.refreshText}
                      >
                        Final Step: Refresh your Backoffice access
                      </a>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {isOrderPlaced && (
              <div className={styles.action}>
                <Button
                  className={styles.button}
                  size="large"
                  withArrow
                  component="button"
                  onClick={redirectToBeBackOffice}
                >
                  {publisherDashboardMessage}
                </Button>
              </div>
            )}

            {process.env.GATSBY_INSTANCE_NAME === 'befreedom' && orderStatus === 'failed' && (
              <div className={styles.action}>
                <Button
                  className={styles.button}
                  size="large"
                  component="button"
                  onClick={redirectToAlternativePm}
                >
                  Checkout with alternative payment method
                </Button>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default CheckoutSuccess;

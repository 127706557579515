import React from 'react';

export const RefreshIcon = () => {
	return (
    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3264_11307)">
        <path
          d="M9.39583 3.66667C8.75783 2.20963 7.18529 1.375 5.48992 1.375C3.34537 1.375 1.58262 3.003 1.375 5.0875"
          stroke="#5D01A2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.55778 3.8501H9.37737C9.40991 3.85016 9.44214 3.8438 9.47222 3.83139C9.5023 3.81898 9.52964 3.80076 9.55267 3.77777C9.5757 3.75478 9.59397 3.72748 9.60644 3.69742C9.61891 3.66736 9.62532 3.63514 9.62532 3.6026V1.7876M1.60449 7.33343C2.24249 8.79047 3.81503 9.6251 5.51041 9.6251C7.65495 9.6251 9.4177 7.9971 9.62532 5.9126"
          stroke="#5D01A2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M3.44254 7.1499H1.62296C1.59042 7.14984 1.55818 7.1562 1.5281 7.16861C1.49802 7.18102 1.47068 7.19924 1.44765 7.22223C1.42462 7.24522 1.40635 7.27252 1.39388 7.30258C1.38142 7.33264 1.375 7.36486 1.375 7.3974V9.2124"
          stroke="#5D01A2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3264_11307">
          <rect width="11" height="11" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
